export const features = {
  RATE_PLAN_QUICK_VIEW: "ratePlanQuickview",
  ONBOARDING: "onboarding",
  CHANNEL_DERIVED_OPTIONS: "channelDerivedOptions",
  GROUP_PROPERTY_MANAGEMENT: "groupPropertyManagement",
  BOOKING_SOURCES_WIDGET: "bookingSourcesWidget",
  LIVE_FEED_NOTIFICATIONS: "liveFeedNotifications",
  DISABLE_USER_MANAGEMENT: "disableUserManagement",
  ENABLE_USER_MANAGEMENT: "enableUserManagement",
  TRIPLA_IMPORT: "triplaImportEnabled",
  APALEO_IMPORT: "apaleoImportEnabled",
  CHANNEL_REPORTS: "channelReports",
  EXTRAS_MANAGEMENT: "extrasManagement",
};

export default {
  [features.RATE_PLAN_QUICK_VIEW]: false,
  [features.ONBOARDING]: false,
  [features.CHANNEL_DERIVED_OPTIONS]: true,
  [features.GROUP_PROPERTY_MANAGEMENT]: true,
  [features.BOOKING_SOURCES_WIDGET]: true,
  [features.LIVE_FEED_NOTIFICATIONS]: false,
  [features.DISABLE_USER_MANAGEMENT]: false,
  [features.ENABLE_USER_MANAGEMENT]: false,
  [features.TRIPLA_IMPORT]: false,
  [features.APALEO_IMPORT]: true,
  [features.CHANNEL_REPORTS]: true,
  [features.EXTRAS_MANAGEMENT]: false,
};
