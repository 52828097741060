import React, { useMemo, useState } from "react";
import ReactDiffViewer from "react-diff-viewer";
import { Flex } from "antd";

import { RevisionSelect } from "./revision_select";

const Container = ({ children }) => (
  <div style={{ width: "50%", padding: "12px 0" }}>
    {children}
  </div>
);

export const BookingRevisionsDiff = ({ booking }) => {
  const { booking_revisions: bookingRevisions } = booking;

  const revisions = useMemo(
    () => Object.values(bookingRevisions).reverse().map(({ raw_message: _raw_message, ...revision }) => revision),
    [bookingRevisions],
  );
  const [revision1, revision2] = revisions.slice(-2);

  const [oldRevision, setOldRevision] = useState(revision1);
  const [newRevision, setNewRevision] = useState(revision2);

  return (
    <Flex flex={1} vertical>
      <Flex>
        <Container>
          <RevisionSelect
            value={oldRevision.id}
            disabledValue={newRevision.id}
            revisions={revisions}
            onChange={setOldRevision}
          />
        </Container>
        <Container>
          <RevisionSelect
            value={newRevision.id}
            disabledValue={oldRevision.id}
            revisions={revisions}
            onChange={setNewRevision}
          />
        </Container>
      </Flex>
      <div style={{ overflowY: "auto", flex: 1 }}>
        <ReactDiffViewer
          styles={{
            line: {
              fontSize: "12px",
            },
            lineNumber: {
              lineHeight: "20px",
            },
            contentText: {
              wordBreak: "break-all",
            },
          }}
          disableWordDiff
          oldValue={JSON.stringify(oldRevision, null, 2)}
          newValue={JSON.stringify(newRevision, null, 2)}
          splitView
        />
      </div>
    </Flex>
  );
};
